import { PageLoader } from "@bay1/ui";
import type { PropsWithChildren } from "react";
import { useContext } from "react";
import { CommonAppContext } from "@bay1/data";
import { getRefreshAuthUri } from "@bay1/data/helpers/uri";
import { checkUserAccess, getUserRole, OpsRoles } from "@bay1/ui/helpers";
import { setTags } from "@sentry/nextjs";
import { useRouter } from "next/router";
import { IntegrationEnvironment, UserRole } from "@bay1/sdk/generated/graphql";
import Custom404 from "../pages/404";
import { hasCookie } from "cookies-next";
import Verify from "../pages/verify";

const blockedRoutesForSupport = [
  "authorization-controls",
  "developer",
  "notifications",
  "events",
  "collab-auth",
  "settings",
  "team",
  "balance",
  "reports",
];

const blockedRoutesForNonRiskAgents = ["transfer-controls"];

const blockedRoutesForNonDisputeAgents = ["disputes"];

const SignedIn = ({
  children,
}: Readonly<PropsWithChildren<unknown>>): JSX.Element => {
  const { user, loading, organizations, activeOrganization, localStore } =
    useContext(CommonAppContext);

  const router = useRouter();

  const { id: organizationIds } = router.query;

  const [organizationId] = Array(organizationIds).flat();

  if (loading) {
    return <PageLoader />;
  }

  if (!user || user.email === undefined) {
    // eslint-disable-next-line fp/no-mutation
    window.location.href = getRefreshAuthUri(window.location.href);

    return <></>;
  }

  setTags({
    integrationEnvironment: activeOrganization?.profile.environment,
    role: getUserRole(user.roles),
    organizationName: activeOrganization?.profile.displayName,
    organizationId: activeOrganization?.id,
    isInternalUser:
      user.email?.includes("highnote") || user.email?.includes("bay1")
        ? true
        : false,
    isAgentServicingUser: activeOrganization?.isAdminUserAccessOrg,
  });

  if (
    (blockedRoutesForSupport.some((route) => {
      if (route === "events") {
        return (
          router.pathname.includes(route) &&
          !router.pathname.includes("card-products")
        );
      }
      return router.pathname.includes(route);
    }) &&
      user.roles &&
      !checkUserAccess([UserRole.ADMIN], user.roles)) ||
    router.pathname.includes("404") ||
    (router.pathname.includes("create-card-product") &&
      activeOrganization?.profile.environment ===
        IntegrationEnvironment.LIVE) ||
    (blockedRoutesForNonRiskAgents.some((route) =>
      router.pathname.includes(route),
    ) &&
      !activeOrganization?.agentRoles?.includes(OpsRoles.RISK_MGMT_MANAGER) &&
      !activeOrganization?.agentRoles?.includes(OpsRoles.RISK_MGMT_SUPPORT)) ||
    (blockedRoutesForNonDisputeAgents.some((route) =>
      router.pathname.includes(route),
    ) &&
      !activeOrganization?.agentRoles?.includes(
        OpsRoles.DISPUTES_MGMT_MANAGER,
      ) &&
      !activeOrganization?.agentRoles?.includes(
        OpsRoles.DISPUTES_MGMT_SUPPORT,
      )) ||
    (process.env.NEXT_PUBLIC_BLOCK_UNVERIFIED_USERS === "true" &&
      !activeOrganization?.isAdminUserAccessOrg &&
      !user.emailVerified) ||
    ((user.email?.includes("highnote") || user.email?.includes("bay1")) &&
      !activeOrganization?.isAdminUserAccessOrg &&
      !user.emailVerified)
  ) {
    if (
      process.env.NEXT_PUBLIC_BLOCK_UNVERIFIED_USERS === "true" &&
      !activeOrganization?.isAdminUserAccessOrg &&
      !user.emailVerified
    ) {
      return <Verify />;
    }

    if (
      (user.email?.includes("highnote") || user.email?.includes("bay1")) &&
      !activeOrganization?.isAdminUserAccessOrg &&
      !user.emailVerified
    ) {
      return <Verify />;
    }
    return <Custom404 />;
  }
  const referrer = localStore?.getRedirectURL() ?? "";

  if (referrer.length > 0) {
    localStore?.setRedirectURL("");

    // zendesk link redirect - ignore referrer
    if (!hasCookie("ops-grantAdminUserAccess")) {
      // eslint-disable-next-line fp/no-mutation
      window.location.href = decodeURIComponent(referrer);
    }
  }

  if (
    router.pathname.includes("/auth/organizations") ||
    router.pathname.includes("/profile") ||
    router.pathname.includes("plan-your-launch")
  ) {
    return <>{children}</>;
  }
  if (organizations && organizations.length > 0) {
    if (activeOrganization?.id !== undefined && organizationId === undefined) {
      void router.push(`organizations/${activeOrganization.id}/home`);
    } else if (
      activeOrganization?.id === undefined &&
      organizationId === undefined
    ) {
      void router.push(`organizations/${organizations[0].id}/home`);
    } else {
      return <>{children}</>;
    }
  }

  return <></>;
};

export default SignedIn;

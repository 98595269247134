import { useRouter } from "next/router";
import type { SyntheticEvent } from "react";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useClickAway } from "react-use";
import { MARKETING_URL } from "@bay1/ui/urlHelper";
import Link from "next/link";
import classNames from "classnames";

import { formatPhoneNumberSearch, formatLast4Search } from "./formatters";
import { useCardProducts } from "../hooks/useCardProducts";

const requiresCardProduct = (id?: string): boolean => {
  if (!id) return false;

  return (
    id.startsWith("ap_") ||
    id.startsWith("fg_") ||
    id.startsWith("ac_") ||
    id.startsWith("cd_") ||
    id.startsWith("te_") ||
    id.startsWith("eftet_") ||
    id.startsWith("fxsch_") ||
    id.startsWith("ia_")
  );
};

const SearchPill = ({
  title,
  description,
  handleAddFilter,
}: Readonly<{
  title: string;
  description: string;
  handleAddFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
}>): JSX.Element => (
  <button
    className="text-xxs hover:bg-ash focus:bg-ash rounded-rounded flex w-full cursor-pointer items-center space-x-2 p-1 focus:outline-none sm:text-xs"
    data-testid={`searchTag::${title}`}
    id={title}
    onClick={handleAddFilter}
    type="button"
  >
    <div className="bg-bone rounded-button text-xxs flex w-max items-center p-1 px-2">
      {title}:
    </div>

    <span className="truncate">{description}</span>
  </button>
);

const searchTags: Record<string, string> = {
  name: "Person Account Holder Full Name",
  nameBusiness: "Business Account Holder Name",
  nameAuthorizedPerson: "Primary Authorized Person Full Name",
  email: "Person Account Holder Email",
  phone: "Person Account Holder Phone Number",
  last4: "Payment Card Last Four Digits",
  id: "Highnote ID",
};

export const SearchBar = (): JSX.Element => {
  const router = useRouter();
  const { id: organizationIds } = router.query;
  const [organizationId] = Array(organizationIds).flat();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showSelectCardProduct, setShowSelectCardProduct] = useState(false);
  const [cardProductId, setCardProductId] = useState<string>();
  const [error, setError] = useState<Error>();

  const [searchString, setSearchString] = useState<string>("");

  const inputReference = useRef<HTMLInputElement>(null);

  const { cardProducts } = useCardProducts();

  useEffect(() => {
    function handleKeyDown(event: { metaKey: boolean; key: string }) {
      if (event.metaKey && event.key === "k") {
        setShowDropdown(true);
        inputReference.current?.focus();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (cardProducts && cardProducts.length === 1) {
      setCardProductId(cardProducts[0]?.id);
    }
  }, [cardProducts]);

  useEffect(() => {
    const hasCardProducts = cardProducts && cardProducts.length > 1;
    const needsCardProduct =
      searchString?.includes("last4") ||
      (searchString?.includes("id") &&
        requiresCardProduct(searchString?.split(":")[1]?.trim()));

    setShowSelectCardProduct(hasCardProducts && needsCardProduct);
  }, [cardProducts, searchString]);

  const handleShowDropdown = useCallback(() => {
    if (!showDropdown) {
      setShowDropdown(true);
    }
  }, [showDropdown]);

  const handleCloseDropdown = useCallback(() => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  }, [showDropdown]);

  const handleSetCardProductId = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setCardProductId(event.currentTarget.id);
      setError(undefined);
      inputReference.current?.focus();
    },
    [],
  );

  const handleSearchStringChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setError(undefined);

      if (event.currentTarget.value.includes("phone:")) {
        setSearchString(
          `${event.currentTarget.value.slice(0, 6)} ${formatPhoneNumberSearch(
            event.currentTarget.value,
          )}`.trim(),
        );
      } else if (event.currentTarget.value.includes("last4:")) {
        setShowDropdown(true);
        setSearchString(
          `${event.currentTarget.value.slice(0, 6)} ${formatLast4Search(
            event.currentTarget.value,
          )}`.trim(),
        );
      } else if (
        event.currentTarget.value.includes("id:") &&
        requiresCardProduct(event.currentTarget.value.split(":")[1]?.trim())
      ) {
        setShowDropdown(true);
        setSearchString(event.currentTarget.value);
      } else {
        setSearchString(event.currentTarget.value);
      }
    },
    [],
  );

  const handleSelectSearchId = useCallback(
    (searchId: string) => {
      if (searchId.startsWith("ap_")) {
        router.push(
          `/organizations/${organizationId}/card-products/${cardProductId}/applications/${searchId}`,
        );
      } else if (searchId.startsWith("fg_")) {
        router.push(
          `/organizations/${organizationId}/card-products/${cardProductId}/card-orders/${searchId}`,
        );
      } else if (searchId.startsWith("og_ah02")) {
        router.push(
          `/organizations/${organizationId}/account-holders/${searchId}`,
        );
      } else if (searchId.startsWith("ps_ah01")) {
        router.push(
          `/organizations/${organizationId}/account-holders/${searchId}`,
        );
      } else if (searchId.startsWith("ps_ap01")) {
        router.push(
          `/organizations/${organizationId}/authorized-users/${searchId}`,
        );
      } else if (searchId.startsWith("ac_")) {
        router.push(
          `/organizations/${organizationId}/card-products/${cardProductId}/${searchId}`,
        );
      } else if (searchId.startsWith("cd_")) {
        router.push(
          `/organizations/${organizationId}/card-products/${cardProductId}/payment-cards/${searchId}`,
        );
      } else if (searchId.startsWith("sr_")) {
        router.push(
          `/organizations/${organizationId}/authorization-controls/spend-rules/${searchId}`,
        );
      } else if (searchId.startsWith("vr_")) {
        router.push(
          `/organizations/${organizationId}/authorization-controls/velocity-controls/${searchId}`,
        );
      } else if (searchId.startsWith("te_")) {
        router.push(
          `/organizations/${organizationId}/card-products/${cardProductId}/transaction-events/${searchId}`,
        );
      } else if (searchId.startsWith("rpt")) {
        router.push(`/organizations/${organizationId}/reports/${searchId}`);
      } else if (searchId.startsWith("eftet_")) {
        router.push(
          `/organizations/${organizationId}/card-products/${cardProductId}/transfers/${searchId}`,
        );
      } else if (searchId.startsWith("fxsch_")) {
        router.push(
          `/organizations/${organizationId}/card-products/${cardProductId}/settings/fees/${searchId}`,
        );
      } else if (searchId.startsWith("ntt_")) {
        router.push(
          `/organizations/${organizationId}/notifications/targets/${searchId}`,
        );
      } else if (searchId.startsWith("nee_")) {
        router.push(`/organizations/${organizationId}/events/${searchId}`);
      } else if (searchId.startsWith("ia_")) {
        router.push(
          `/organizations/${organizationId}/card-products/${cardProductId}/transfers/${searchId}`,
        );
      } else {
        setShowDropdown(true);
        setError(new Error("Invalid search. Must provide a valid ID."));
      }
    },
    [cardProductId, organizationId, router],
  );

  const handleSetCurrentFilterField = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setError(undefined);
      inputReference.current?.focus();
      setSearchString(`${event.currentTarget.id}: `);
      setShowDropdown(false);
    },
    [],
  );

  const handleSubmit = useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      const valueAfterTag = searchString.split(":")[1];

      if (valueAfterTag && !valueAfterTag.trim()) {
        setShowDropdown(true);
        inputReference.current?.blur();
        setError(new Error("Invalid search. Must enter a value."));
        return;
      }

      if (searchString.includes("email:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/account-holders`,
          query: { email: searchString.split(":")[1].trim() },
        });
      } else if (searchString.includes("name:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/account-holders`,
          query: { name: searchString.split(":")[1].trim() },
        });
      } else if (searchString.includes("phone:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/account-holders`,

          query: {
            phone: [
              searchString.split(":")[1].replaceAll("-", "").trim(),
              searchString.split(":")[1].trim(),
            ],
          },
        });
      } else if (searchString.includes("nameBusiness:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/business-account-holders`,
          query: { legalBusinessName: searchString.split(":")[1].trim() },
        });
      } else if (searchString.includes("nameAuthorizedPerson:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/business-account-holders`,
          query: { authorizedPersonName: searchString.split(":")[1].trim() },
        });
      } else if (searchString.includes("last4:")) {
        if (cardProductId === undefined) {
          setShowDropdown(true);
          setError(new Error("Invalid search. Must select a card product."));
          return;
        }

        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/card-products/${cardProductId}/payment-cards`,

          query: {
            last4: searchString.split(":")[1].trim(),
            cardProductId,
          },
        });
      } else if (searchString.includes("id:")) {
        const highnoteId = searchString.split(":")[1].trim();

        if (requiresCardProduct(highnoteId) && cardProductId === undefined) {
          setShowDropdown(true);
          setError(new Error("Invalid search. Must select a card product."));
          return;
        }

        setShowDropdown(false);
        handleSelectSearchId(highnoteId);
      } else {
        setShowDropdown(true);
        setError(new Error("Invalid search. Must use a tag."));
      }
      inputReference.current?.blur();
    },
    [searchString, router, organizationId, cardProductId, handleSelectSearchId],
  );

  useEffect(() => {
    setSearchString("");
  }, [router.query]);

  useClickAway(inputReference, (event) => {
    const path = event.composedPath() as Element[];
    if (
      !path.some(
        (element) => element.id === "search" || element.id === "dropdown",
      )
    ) {
      setShowDropdown(false);
    }
  });

  return (
    <div className="group relative w-full">
      <form onSubmit={handleSubmit}>
        <label className="relative block w-full sm:w-2/3">
          <span className="sr-only">Search</span>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <img alt="" src="/img/search-icon.svg" />
          </div>
          <input
            autoComplete="off"
            className="block h-10 w-full truncate rounded-full border-transparent bg-white pl-10 pr-2 text-xs hover:border-black focus:border-black focus:outline-none focus:ring-1 focus:ring-black"
            data-testid="input::search"
            id="search"
            maxLength={100}
            name="search"
            onChange={handleSearchStringChange}
            onClick={handleShowDropdown}
            onFocus={handleShowDropdown}
            placeholder="Search"
            ref={inputReference}
            type="text"
            value={searchString}
          />
        </label>

        {showDropdown && (
          <div
            className="rounded-rounded ring-ash absolute mt-2 w-full bg-white shadow-lg ring-1 sm:w-2/3"
            id="dropdown"
          >
            <div className="space-y-0.5 p-2">
              {error === undefined ? (
                <div className="text-xxs pb-1.5 pl-1 pt-1 text-gray-500">
                  Select a tag
                </div>
              ) : (
                <div
                  className="text-red pl-1 text-xs"
                  data-testid="search::error"
                >
                  {error.message}
                </div>
              )}

              {Object.entries(searchTags).map(([title, description]) => {
                if (
                  title.concat(":").startsWith(searchString) ||
                  searchString.startsWith(title)
                ) {
                  return (
                    <SearchPill
                      description={description}
                      handleAddFilter={handleSetCurrentFilterField}
                      key={title}
                      title={title}
                    />
                  );
                }
                return undefined;
              })}
            </div>

            {showSelectCardProduct && (
              <div className="pb-5 pl-3">
                <div className="text-xxs py-2 text-gray-500">
                  And select a card product
                </div>

                {cardProducts.map((cardProduct) => (
                  <div className="inline-block" key={cardProduct?.id}>
                    <button
                      className={classNames(
                        "text-xxs rounded-button mb-1 mr-1 cursor-pointer items-center px-3  py-1  focus:outline-none",

                        {
                          "bg-black text-white":
                            cardProductId === cardProduct?.id,

                          "bg-bone hover:bg-ash focus:bg-black focus:text-white":
                            cardProductId !== cardProduct?.id,
                        },
                      )}
                      id={cardProduct?.id}
                      onClick={handleSetCardProductId}
                      type="button"
                    >
                      {cardProduct?.name}
                    </button>
                  </div>
                ))}
              </div>
            )}

            <Link
              href={`${MARKETING_URL}/docs/basics/dashboard/perform-searches`}
              passHref
              className="bg-bone rounded-b-rounded text-xxs hover:bg-ash focus:bg-ash flex w-full px-3 py-2 focus:outline-none"
              data-testid="search::documentation-link"
              onBlur={handleCloseDropdown}
              rel="noreferrer"
              target="_blank"
            >
              Learn search tips
            </Link>
          </div>
        )}
      </form>
    </div>
  );
};
